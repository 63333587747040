.valentine-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(to bottom, #ffcccc, #ff99cc);
}

.valentine-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
}

.valentine-image {
  border-radius: 8px;
  height: 300px;
  object-fit: cover;
}

.valentine-question {
  font-size: 2rem;
  font-weight: bold;
  font-style: italic;
  color: #bd1e59;
  margin: 16px 0;
}

.button-container {
  display: flex;
  gap: 16px;
  padding-top: 20px;
  align-items: center;
}

.yes-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #28a745;
  color: white;
  transition: background-color 0.3s ease;
}

.yes-button:hover {
  background-color: #218838;
}

.no-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: #dc3545;
  color: white;
  height: 48px;
  min-width: 75px;
  padding: 8px 16px;
  transition: background-color 0.3s ease;
}

.no-button:hover {
  background-color: #c82333;
}
